import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from '../../authContext/authContext';
import './ETFDetailsOverlay.css';

const ETFDetailsOverlay = ({ symbol, onClose }) => {
  const [etf, setEtf] = useState(null);
  const [orderType, setOrderType] = useState('buy');
  const [quantity, setQuantity] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;
  const python_backend = process.env.REACT_APP_PYTHON_BACKEND_URL;

  const { authData } = useContext(AuthContext);
  const formRef = useRef(null);

  useEffect(() => {
    const fetchETFDetails = async () => {
      try {
        const response = await axios.get(`${node_backend}/api/etf/etfs/${symbol}/details`);
        setEtf(response.data);
      } catch (error) {
        console.error("There was an error fetching the ETF details!", error);
      }
    };

    fetchETFDetails();
  }, [node_backend, symbol]);

  const handleOrder = () => {
    if (authData && authData.email && quantity > 0) {
      const totalPrice = etf.price * quantity;
      setOrderDetails({
        symbol,
        orderType,
        quantity,
        totalPrice,
      });
      setShowConfirm(true);
    }
  };

  const confirmOrder = async () => {
    setShowConfirm(false);
    try {
      await axios.post(`${python_backend}/api/orders/place`, {
        email: authData.email,
        symbol,
        orderType,
        quantity
      });
      alert(`${orderType.toUpperCase()} order placed for ${quantity} shares of ${symbol}`);
      window.location.reload();
    } catch (error) {
      console.error(`There was an error placing the ${orderType} order!`, error);
      alert(`Failed to place ${orderType} order. Please try again.`);
    }
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && Number(value) >= 0) {
      setQuantity(value);
    }
  };

  if (!etf) {
    return <div></div>;
  }

  return (
    <div className="etf-details-overlay">
      <div className="overlay-content">
        <button className="close-btn" onClick={onClose}>Close</button>
        <h1>{etf.symbol} Details</h1>
        <p>Price: ₹{etf.price}</p>
        <p>Change: {etf.change > 0 ? '+' : ''}{etf.change.toFixed(2)} ({etf.changePercent.toFixed(2)}%)</p>
        <div className="order-section">
          <label>Order Type:</label>
          <select value={orderType} onChange={(e) => setOrderType(e.target.value)}>
            <option value="buy">Buy</option>
            <option value="sell">Sell</option>
          </select>
          <label>Quantity:</label>
          <input type="number" value={quantity} onChange={handleQuantityChange} min="0" />
          <button
            onClick={handleOrder}
            disabled={!authData.creds}
            className={`${orderType}-btn ${!authData.creds ? 'disabled-button' : ''}`}
          >
            {orderType.toUpperCase()} ETF
          </button>
          {!authData.creds && (
            <p className="message">
              Please <a href="/profile" className="profile-link">go to your profile</a> to submit your credentials before you can proceed with buying or selling ETFs.
            </p>
          )}
        </div>
        {showConfirm && (
          <div className="confirmation-dialog">
            <h2>Confirm Order</h2>
            <p><strong>Order Type:</strong> {orderDetails.orderType.toUpperCase()}</p>
            <p><strong>Symbol:</strong> {orderDetails.symbol}</p>
            <p><strong>Quantity:</strong> {orderDetails.quantity}</p>
            <p><strong>Total Price:</strong> ₹{orderDetails.totalPrice.toFixed(2)}</p>
            <button onClick={confirmOrder} className="confirm-btn">Confirm Order</button>
            <button onClick={() => setShowConfirm(false)} className="cancel-btn">Cancel</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ETFDetailsOverlay;
