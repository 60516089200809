import React, { useRef, useState, useContext } from 'react';
import axios from 'axios';
import './ProductPage.css';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../authContext/authContext';

const Products = () => {
  const pricingRef = useRef(null);
  const [showPaymentForm, setShowPaymentForm] = useState({ tier: '', visible: false });
  const navigate = useNavigate();
  const { authData } = useContext(AuthContext);

  const handlePurchaseClick = (tier) => {
    setShowPaymentForm({ tier, visible: true });
  };

  const handlePaymentSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.name.value;
    const amountPaid = e.target.amountPaid.value;
    const refNumber = e.target.refNumber.value;
    const backendUrl = process.env.REACT_APP_NODE_BACKEND_URL;

    try {
      const response = await axios.post(`${backendUrl}/api/payments/payment`, {
        tier: showPaymentForm.tier,
        name,
        amountPaid,
        refNumber,
        userId: authData._id,
      });

      if (response.data.success) {
        alert('Form Submitted. Our team will Activate your account after payment verification!');
        setShowPaymentForm({ tier: '', visible: false });
        navigate("/strategy");
      } else {
        alert('Payment failed, please try again.');
      }
    } catch (error) {
      console.error('Payment submission error:', error);
      alert('An error occurred, please try again.');
    }
  };

  const handleClosePopup = () => {
    setShowPaymentForm({ tier: '', visible: false });
  };

  return (
    <div className="products-container">
      <header className="products-header">
        <h1>Our Products</h1>
      </header>
      <section className="pricing-section" ref={pricingRef}>
        <div className="pricing-card-container">
          <div className="pricing-card">
            <h3>Free</h3>
            <p>Explore our strategies for free on your dashboard. Get insights into features and profit expectancy before deciding to automate your investing.</p>
            <ul>
              <li className="checked">Tradeable ETFs</li>
              <li className="unchecked">Get Updates about ETF Prices</li>
              <li className="unchecked">Buying Recommendations</li>
              <li className="unchecked">Buy/Sell from the Website</li>
              <li className="unchecked">Selling Recommendations</li>
              <li className="unchecked">Fully Automated</li>
            </ul>
          </div>
          <div className="pricing-card">
            <h3>Silver</h3>
            <p>Get buying and selling recommendations everyday provided by our algorithm. Execute trades manually with your broker. Perfect for hands-on traders.</p>
            <ul>
              <li className="checked">Tradeable ETFs</li>
              <li className="checked">Get Updates about ETF Prices</li>
              <li className="checked">Buying Recommendations</li>
              <li className="checked">Selling Recommendations</li>
              <li className="checked">Buy/Sell from the Website</li>
              <li className="unchecked">Fully Automated</li>
            </ul>
            <button className="purchase-button" onClick={() => handlePurchaseClick('Silver')}>Purchase - ₹199/Month or ₹1999/Year</button>
          </div>
          <div className="pricing-card">
            <h3>Gold</h3>
            <p>Let our algorithm handle your trades automatically. Ideal for a hassle-free trading. Currently available for Shoonya users, more brokers coming soon.</p>
            <ul>
              <li className="checked">Tradeable ETFs</li>
              <li className="checked">Get Updates about ETF Prices</li>
              <li className="checked">Buying Recommendations</li>
              <li className="checked">Selling Recommendations</li>
              <li className="checked">Buy/Sell from the Website</li>
              <li className="checked">Fully Automated</li>
            </ul>
            <button className="purchase-button" onClick={() => handlePurchaseClick('Gold')}>Purchase - ₹299/Month or ₹2999/Year</button>
          </div>
        </div>
        {showPaymentForm.visible && (
          <div className="overlay">
            <div className="popup">
              <button className="close-button" onClick={handleClosePopup}>&times;</button>
              <form className="payment-form" onSubmit={handlePaymentSubmit}>
                <h3>Complete Your Purchase for {showPaymentForm.tier} Pack</h3>
                <img src={process.env.PUBLIC_URL + '/qr.jpeg'} alt="QR Code" className="qr-code" />
                <input type="text" name="name" placeholder="Name" required />
                <input type="text" name="amountPaid" placeholder="Amount Paid" required />
                <input type="text" name="refNumber" placeholder="Last 4 Digits of Ref. Number" required />
                <button type="submit" className="submit-payment">Submit</button>
              </form>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default Products;
