import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Navbar.css';
import { AuthContext } from '../../authContext/authContext';
import { useNavigate } from 'react-router-dom';
// import logo from 'logo_black.jpg'; // Make sure to adjust the path if needed

const Navbar = () => {
  const { authData, setAuthData } = useContext(AuthContext);
  const navigate = useNavigate();
  let logoClass = '';
  if (authData) {
    if (authData.plan === 'Silver') {
      logoClass = 'silver-shadow';
    } else if (authData.plan === 'Gold') {
      logoClass = 'gold-shadow';
    }
  }


  const logout = () => {
    localStorage.removeItem('authToken');
    setAuthData(null);
    navigate('/login');
  };


  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="header-left">

          <a href="/" className='navbar-logo'>
            <div className={`logo ${logoClass}`}>

              <img src={process.env.PUBLIC_URL + '/logo_black.jpg'} alt="Logo" className="navbar-logo" />
            </div>
          </a>
        </div>
        <div className="header-right">
          <ul className="navLinks">
            {authData && (
              <>

                <li className='navLink'>
                  <Link to="/dashboard" className="navLink">Dashboard</Link>
                </li>
                <li className='navLink'>
                  <Link to="/strategy" className="navLink">Strategy</Link>
                </li>
              </>
            )}
            <li className='navLink'>
              <Link to="/products" className="navLink">Products</Link>
            </li>
            <li className='navLink'>
              <Link to="/contact-us" className="navLink">Contact Us</Link>
            </li>
            {!authData ? (
              <li className='navLink'>
                <Link to="/login" className="navLink">Login</Link>
              </li>
            ) : (
              <>
                <li className='navLink'>
                  <Link to="/profile" className="navLink profile">Profile</Link> {/* Profile Link */}
                </li>
                <li className='navLink'>
                  <button onClick={logout} className="navLink">Logout</button>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
