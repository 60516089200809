import React, { useState } from 'react';
import './BacktestedResults.css';

const BacktestedResults = ({ onClose }) => {
  const lumpSumImages = [
    { label: "Aug 19 - Jul 24", src: process.env.PUBLIC_URL + "/backtest/aug19-jul24.jpg", description: "Returns if you invested in Aug 19, compared to Nifty 50 benchmark." },
    { label: "Jan 20 - Jul 24", src: process.env.PUBLIC_URL + "/backtest/jan20-jul24.jpg", description: "Returns if you invested in Jan 20, compared to Nifty 50 benchmark." },
    { label: "Jan 21 - Jul 24", src: process.env.PUBLIC_URL + "/backtest/jan21-jul24.jpg", description: "Returns if you invested in Jan 21, compared to Nifty 50 benchmark." },
    { label: "Jan 22 - Jul 24", src: process.env.PUBLIC_URL + "/backtest/jan22-jul24.jpg", description: "Returns if you invested in Jan 22, compared to Nifty 50 benchmark." },
    { label: "Jan 23 - Jul 24", src: process.env.PUBLIC_URL + "/backtest/jan23-jul24.jpg", description: "Returns if you invested in Jan 23, compared to Nifty 50 benchmark." }
  ];

  const sipImages = [
    { label: "Aug 19 - Jul 24 SIP", src: process.env.PUBLIC_URL + "/backtest/aug19-jul24-sip.jpg", description: "SIP returns if you started in Aug 19, compared to Nifty 50 benchmark." },
    { label: "Jan 20 - Jul 24 SIP", src: process.env.PUBLIC_URL + "/backtest/jan20-jul24-sip.jpg", description: "SIP returns if you started in Jan 20, compared to Nifty 50 benchmark." },
    { label: "Jan 21 - Jul 24 SIP", src: process.env.PUBLIC_URL + "/backtest/jan21-jul24-sip.jpg", description: "SIP returns if you started in Jan 21, compared to Nifty 50 benchmark." },
    { label: "Jan 22 - Jul 24 SIP", src: process.env.PUBLIC_URL + "/backtest/jan22-jul24-sip.jpg", description: "SIP returns if you started in Jan 22, compared to Nifty 50 benchmark." },
    { label: "Jan 23 - Jul 24 SIP", src: process.env.PUBLIC_URL + "/backtest/jan23-jul24-sip.jpg", description: "SIP returns if you started in Jan 23, compared to Nifty 50 benchmark." }
  ];

  const [selectedImages, setSelectedImages] = useState(lumpSumImages);
  const [selectedImage, setSelectedImage] = useState(lumpSumImages[0]);
  const [investmentType, setInvestmentType] = useState('lumpsum');

  const handleInvestmentTypeChange = (type) => {
    setInvestmentType(type);
    const newImages = type === 'lumpsum' ? lumpSumImages : sipImages;
    setSelectedImages(newImages);
    setSelectedImage(newImages[0]);
  };

  return (
    <div className="backtested-results-overlay">
      <div className="backtested-results-container">
        <div className="backtested-results-left">
          <h3>Acknowledgments</h3>
          <p>
            This strategy has been inspired by Mahesh Kaushik, whose insights into market dynamics and disciplined approach to investing have guided many to financial success. His passion and dedication to refining and sharing effective strategies have been invaluable.
          </p>
          <h3>Strategy Explanation</h3>
          <p>
            This strategy, inspired by guidance from the well-known investor Mahesh Chandra Kaushik, focuses on investing in India's most liquid ETFs (Exchange Traded Funds) across key sectors. It’s a simple, low-risk method designed to help investors buy ETFs when their prices are lower and sell them when they rise.
          </p>
          <h4>Here’s how it works:</h4>
          <ol>
            <li><strong>Daily ETF Purchase:</strong> Each day, you buy one ETF from a selected list. The ETFs are ranked based on how far their current price is from their 10-day moving average. You invest 2.5% of your total capital in the account each day.</li>
            <li><strong>Buying at Lows:</strong> The strategy focuses on buying ETFs that aren't performing well at the moment, aiming to buy them when prices are low and sell them later at a profit when prices rise.</li>
            <li><strong>Profit Booking:</strong>
              <ul>
                <li>Once an ETF gains 6.5% in value, a trailing stop-loss order is set up to ensure you lock in at least 6% profit.</li>
                <li>If all your capital is invested, the strategy aims to sell at a 3% profit to free up funds for buying more ETFs at lower prices.</li>
              </ul>
            </li>
          </ol>
          <p>The idea is to gradually build your portfolio, buying ETFs when they are cheaper and selling them as they recover, ensuring steady growth over time.</p>
        </div>

        <div className="backtested-results-right">
          <div className="controls-section">
            <div className="investment-type-selector">
              <label>
                <input
                  type="radio"
                  value="lumpsum"
                  checked={investmentType === 'lumpsum'}
                  onChange={() => handleInvestmentTypeChange('lumpsum')}
                />
                Lump Sum
              </label>
              <label>
                <input
                  type="radio"
                  value="sip"
                  checked={investmentType === 'sip'}
                  onChange={() => handleInvestmentTypeChange('sip')}
                />
                SIP
              </label>
            </div>
            <ul className="result-buttons">
              {selectedImages.map((image, index) => (
                <li key={index}>
                  <button
                    onClick={() => setSelectedImage(image)}
                    className={selectedImage.label === image.label ? 'selected' : ''}
                  >
                    {image.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="chart-section">
            {selectedImage && (
              <>
                <img src={selectedImage.src} alt={selectedImage.label} />
                <p>{selectedImage.description}</p>
              </>
            )}
          </div>
        </div>
        <button className="close-btn" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default BacktestedResults;
