import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
    return (
        <div className="contact-container">
            <h1 className="contact-title">Contact Us</h1>
            <div className="contact-info">
                <p className="contact-name">Admin</p>
                <p className="contact-number">Phone: Coming Soon ..... </p>
            </div>
        </div>
    );
};

export default ContactUs;
