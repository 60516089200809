import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../authContext/authContext';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import ETFDetailsOverlay from '../ETFDetailsOverlay/ETFDetailsOverlay';

const Dashboard = () => {
  const [etfs, setEtfs] = useState([]);
  const [watchlist, setWatchlist] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const navigate = useNavigate();
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;
  const { authData } = useContext(AuthContext);
  const fetchData = async () => {
    try {
      const response = await axios.get(`${node_backend}/api/etf/etfs`);
      const updatedEtfs = await Promise.all(
        response.data.map(async (etf) => {
          const now = new Date();
          const etfLastUpdate = new Date(etf.updatedAt);
          if ((now - etfLastUpdate) / 1000 > 60) {
            const updatedResponse = await axios.post(`${node_backend}/api/etf/etfs/${etf.symbol}`);
            return updatedResponse.data;
          }
          return etf;
        })
      );
      setEtfs(updatedEtfs);
    } catch (error) {
      console.error('There was an error fetching the ETF data!', error);
    }

    if (authData && authData.email) {
      try {
        const watchlistResponse = await axios.get(`${node_backend}/api/watchlist/watchlist`, {
          params: { email: authData.email },
        });
        setWatchlist(watchlistResponse.data.watchlist || []);
      } catch (error) {
        console.error('There was an error fetching the watchlist data!', error);
      }
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 60000);

    return () => clearInterval(intervalId);
  }, [node_backend, authData]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setDropdownVisible(event.target.value.length > 0);
  };

  const addToWatchlist = (etf) => {
    if (authData && authData.email) {
      axios
        .post(`${node_backend}/api/watchlist/add`, { email: authData.email, symbol: etf.symbol })
        .then((response) => {
          setWatchlist([...watchlist, etf]);
          setDropdownVisible(false);
          setSearchTerm('');
        })
        .catch((error) => {
          console.error('There was an error adding the ETF to the watchlist!', error);
        });
    }
  };

  const removeFromWatchlist = (symbol) => {
    if (authData && authData.email) {
      axios
        .post(`${node_backend}/api/watchlist/delete`, { email: authData.email, symbol: symbol })
        .then((response) => {
          setWatchlist(watchlist.filter((etf) => etf.symbol !== symbol));
        })
        .catch((error) => {
          console.error('There was an error removing the ETF from the watchlist!', error);
        });
    }
  };
  const handleETFClick = (symbol) => {
    setSelectedSymbol(symbol);
    setOverlayVisible(true);
  };

  const closeOverlay = () => {
    setOverlayVisible(false);
    setSelectedSymbol(null);
  };

  const filteredEtfs = etfs.filter((etf) =>
    etf.symbol.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="dashboard">
      <div className="sidebar">
        <h3>Watchlist</h3>
        <input
          type="text"
          placeholder="Search by symbol..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-bar"
        />
        {dropdownVisible && (
          <ul className="dropdown">
            {filteredEtfs.map((etf, index) => (
              <li key={index} className="dropdown-item">
                {etf.symbol} - ₹{etf.price}
                <button onClick={() => addToWatchlist(etf)} className="add-button">
                  Add
                </button>
              </li>
            ))}
          </ul>
        )}
        <ul className="watchlist">
          {watchlist.map((etf, index) => (
            <li
              key={index}
              className={`watchlist-item ${etf.change <= 0 ? 'stock-down' : 'stock-up'}`}
              onClick={() => handleETFClick(etf.symbol)}
            >
              <div className="stock-info">
                <span className="stock-symbol">{etf.symbol}</span>
                <span className="stock-ltp">₹{etf.price}</span>
                <span className="stock-change">
                  {etf.change > 0 ? '+' : ''}
                  {etf.change.toFixed(2)} ({etf.changePercent.toFixed(2)}%)
                </span>
              </div>
              <div className="stock-buttons">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFromWatchlist(etf.symbol);
                  }}
                  className="remove-button"
                >
                  Remove
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {overlayVisible && (
        <ETFDetailsOverlay symbol={selectedSymbol} onClose={closeOverlay} />
      )}
    </div>
  );
};

export default Dashboard;