import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(null);
  const [loading, setLoading] = useState(false);
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL; // Accessing backend URL from .env

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        setLoading(true);
        try {
          const response = await axios.get(`${node_backend}/api/auth/user`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setAuthData(response.data);
        } catch (error) {
          console.error('Failed to fetch user data', error);
        }
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const logout = () => {
    localStorage.removeItem('authToken');
    setAuthData(null);
  };

  return (
    <AuthContext.Provider value={{ authData, setAuthData, logout }}>
      {loading ? <p>Loading...</p> : children}
    </AuthContext.Provider>
  );
};
