import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
// import LandingPage from './pages/LandingPage/LandingPage';
import LandingPage from './pages/LandingPage/LandingPage';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import { AuthProvider } from './authContext/authContext';
import Layout from './components/Layout/Layout';
import Products from './pages/ProductPage/ProductPage';
import HomePage from './pages/HomePage/HomePage';
import Strategy from './pages/Strategy/StrategyPage';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ETFDetails from './pages/ETFDetails/ETFDetails'; // Added this import
import ProfilePage from './pages/ProfilePage/ProfilePage'; // Add this import
import AdminPage from './pages/AdminPage/AdminPage';
import ContactUs from './pages/ContactUs/ContactUs';
function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/products" element={<Products />} />
            <Route path="/forgot-password" element={<ForgotPassword />} /> 
            <Route path="/" element={<LandingPage/>} />
            <Route path="/contact-us" element={<ContactUs />} />

            <Route
              path="*"
              element={
                <Layout>
                  <Routes>
                    <Route path="/dashboard" element={<HomePage />} />
                    <Route path="/strategy" element={<Strategy />} /> 
                    <Route path="/etf/:symbol" element={<ETFDetails />} /> {/* Added this route */}
                    <Route path="/profile" element={<ProfilePage />} /> 


                    {/* Add other routes here */}
                  </Routes>
                </Layout>
              }
            />
            {/* Add other routes here */}
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
