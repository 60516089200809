import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../authContext/authContext';
import './HomePage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import Portfolio from '../DummyPortfolio/DummyPortfolio';

const HomePage = () => {
  const { authData } = useContext(AuthContext);
  const [portfolio, setPortfolio] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [username, setUsername] = useState(authData?.username);
  const [loginFailed, setLoginFailed] = useState(false); // State to track login failure
  const python_backend = process.env.REACT_APP_PYTHON_BACKEND_URL;

  const createPortfolioComponent = () => {
    return <Portfolio />
  };

  useEffect(() => {
    const fetchUsername = async () => {
      if (authData?.creds) {
        try {
          const response = await axios.post(
            `${python_backend}/api/get-username`,
            { email: authData?.email },
            { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
          );
          console.log(response.data);
          if (response.data.success && response.data.uname) {
            setUsername(response.data.uname);
            return true; // Indicate success
          } else {
            console.log(response.data.message);
            if (response.data.message.includes("Login failed")) {
              setLoginFailed(true); // Set login failure state
            }
            return false;
          }
        } catch (error) {
          console.error('Error fetching username:', error);
          return false;
        }
      }
    };

    const fetchPortfolio = async () => {
      const token = localStorage.getItem('authToken');
      if (token && authData && authData.plan !== 'Free' && authData.plan !== 'Silver') {
        try {
          const response = await axios.post(
            `${python_backend}/api/fetch-portfolio`,
            { email: authData?.email },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          if (response.data.success) {
            setPortfolio(response.data.portfolio);
          } else {
            console.error(response.data.message);
          }
        } catch (error) {
          console.error('Error fetching portfolio:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    const initializeData = async () => {
      const usernameFetched = await fetchUsername();
      console.log(usernameFetched);
      if (usernameFetched) {
        fetchPortfolio(); // Only fetch portfolio if username is successfully fetched
      }
    };

    initializeData();
  }, [authData, python_backend]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedPortfolio = [...portfolio].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? faSortUp : faSortDown;
    }
    return faSort;
  };

  return (
    <div className="home-page-container">
      <div className="home-page-main-content">
        <div className={`user-info-container ${authData?.plan}`}>
          <h1>Welcome, {username}!</h1>
          <h2>Your Plan: {authData?.plan}</h2>
        </div>

        <div className="credential-instructions">
          {authData?.plan === 'Gold' || authData?.plan === 'Silver' ? (
            !authData?.creds ? (
              <p>
                Ready to start your trading journey?{' '}
                <div >
                  Click below to download the instructions on how to provide your credentials and begin trading!
                </div>
                <a href="/downloadables/credentials.pdf" download >Shoonya</a>
                <a href="/downloadables/angel-credentials.pdf" download >Angel One</a>
              </p>
            ) : (
              <p>
                You have already provided your credentials.
                {loginFailed ? ( // Show prompt if login fails
                  <div className="login-failed-message">
                    <p>
                      Your credentials have expired or are invalid. Please{' '}
                      <a href="/profile">click here</a> to reset your credentials.
                    </p>
                  </div>
                ) : (
                  authData?.plan === 'Gold' || authData?.plan === 'Silver' ? (
                    <div className="portfolio-section">
                      <h2>Your Portfolio</h2>
                      {authData.plan === 'Silver' ? (
                        createPortfolioComponent()
                      ) : (
                        <div>
                          {loading ? (
                            <p>Loading your portfolio...</p>
                          ) : portfolio.length > 0 ? (
                            <table className="portfolio-table">
                              <thead>
                                <tr>
                                  <th onClick={() => handleSort('symbol')}>
                                    Symbol <FontAwesomeIcon icon={getSortIcon('symbol')} />
                                  </th>
                                  <th onClick={() => handleSort('amount_invested')}>
                                    Amount Invested (₹) <FontAwesomeIcon icon={getSortIcon('amount_invested')} />
                                  </th>
                                  <th onClick={() => handleSort('current_amount')}>
                                    Current Amount (₹) <FontAwesomeIcon icon={getSortIcon('current_amount')} />
                                  </th>
                                  <th onClick={() => handleSort('gain')}>
                                    Gain (₹) <FontAwesomeIcon icon={getSortIcon('gain')} />
                                  </th>
                                  <th onClick={() => handleSort('gain_percentage')}>
                                    Gain (%) <FontAwesomeIcon icon={getSortIcon('gain_percentage')} />
                                  </th>
                                  <th onClick={() => handleSort('number_of_shares')}>
                                    Number of Shares <FontAwesomeIcon icon={getSortIcon('number_of_shares')} />
                                  </th>
                                  <th onClick={() => handleSort('avg_amount_per_share')}>
                                    Avg Amount Per Share (₹) <FontAwesomeIcon icon={getSortIcon('avg_amount_per_share')} />
                                  </th>
                                  <th onClick={() => handleSort('current_value_per_share')}>
                                    Current Value Per Share (₹) <FontAwesomeIcon icon={getSortIcon('current_value_per_share')} />
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {sortedPortfolio.map((holding, index) => (
                                  <tr key={index}>
                                    <td>{holding.symbol}</td>
                                    <td>{holding.amount_invested?.toFixed(2) || "N/A"}</td>
                                    <td>{holding.current_amount?.toFixed(2) || "N/A"}</td>
                                    <td>{holding.gain?.toFixed(2) || "N/A"}</td>
                                    <td>{holding.gain_percentage?.toFixed(2) || "N/A"}</td>
                                    <td>{holding.number_of_shares}</td>
                                    <td>{holding.avg_amount_per_share?.toFixed(2) || "N/A"}</td>
                                    <td>{holding.current_value_per_share?.toFixed(2) || "N/A"}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <p>No holdings found.</p>
                          )}
                        </div>
                      )}
                    </div>
                  ) : null
                )}

              </p>
            )
          ) : (
            <p>
              Upgrade to <a href="/product">Gold or Silver</a> to access the full range of features and get personalized instructions on setting up your trading credentials.
            </p>
          )}
        </div>


      </div>
    </div>
  );
};

export default HomePage;
