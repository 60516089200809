// ForgotPassword.js
import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ForgotPassword.css'; // Use the same CSS as Login page

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [loadingSendOtp, setLoadingSendOtp] = useState(false);
  const [loadingVerifyOtp, setLoadingVerifyOtp] = useState(false);
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);
  const backendUrl = process.env.REACT_APP_NODE_BACKEND_URL; // Accessing backend URL from .env

  const handleSendOtp = async () => {
    setLoadingSendOtp(true);
    try {
      const response = await axios.post(`${backendUrl}/api/auth/forgot-password`, { email });

      if (response.status === 200) {
        const data = response.data;
        if (data.success) {
          setOtpSent(true);
          toast.success('OTP sent to your email!');
        } else {
          toast.error(data.msg || 'Failed to send OTP');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.');
    } finally {
      setLoadingSendOtp(false);
    }
  };

  const handleVerifyOtp = async () => {
    setLoadingVerifyOtp(true);
    try {
      const response = await axios.post(`${backendUrl}/api/auth/verify-otp`, { email, otp });

      if (response.status === 200) {
        const data = response.data;
        if (data.success) {
          setEmailVerified(true);
          toast.success('OTP verified successfully!');
        } else {
          toast.error(data.msg || 'Invalid OTP');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.');
    } finally {
      setLoadingVerifyOtp(false);
    }
  };

  const handleResetPassword = async () => {
    setLoadingResetPassword(true);
    try {
      const response = await axios.post(`${backendUrl}/api/auth/reset-password`, { email, otp, newPassword });

      if (response.status === 200) {
        const data = response.data;
        if (data.success) {
          toast.success('Password reset successfully!');
        } else {
          toast.error(data.msg || 'Failed to reset password');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.');
    } finally {
      setLoadingResetPassword(false);
    }
  };

  return (
    <div className="login-page">
      <ToastContainer />
      <div className="login-container">
        <div className='login-heading'><h2>Forgot Password</h2></div>
        <div className="form-group">
          <label>Email</label>
          <div className="form-input">
            <input
              type="text"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={otpSent}
            />
            <button type="button" onClick={handleSendOtp} disabled={loadingSendOtp || otpSent}>
              {loadingSendOtp ? 'Sending OTP...' : otpSent ? 'OTP Sent' : 'Send OTP'}
            </button>
          </div>
        </div>
        {otpSent && !emailVerified && (
          <div className="form-group">
            <label>OTP</label>
            <div className="form-input">
              <input
                type="text"
                name="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
              <button type="button" onClick={handleVerifyOtp} disabled={loadingVerifyOtp}>
                {loadingVerifyOtp ? 'Verifying...' : 'Verify OTP'}
              </button>
            </div>
          </div>
        )}
        {emailVerified && (
          <div className="form-group">
            <label>New Password</label>
            <div className="form-input">
              <input
                type="password"
                name="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <button type="button" onClick={handleResetPassword} disabled={loadingResetPassword}>
                {loadingResetPassword ? 'Resetting Password...' : 'Reset Password'}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
