// Login.js
import React, { useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';
import { AuthContext } from '../../authContext/authContext';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [redirect, setRedirect] = useState(false);
  const { setAuthData } = useContext(AuthContext);
  const backendUrl = process.env.REACT_APP_NODE_BACKEND_URL; // Accessing backend URL from .env

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleLogin = async (e) => {
    
    e.preventDefault();
    try {
      const nodeBackendUrl = process.env.REACT_APP_NODE_BACKEND_URL;
      const pythonBackendUrl = process.env.REACT_APP_PYTHON_BACKEND_URL;
      
      
console.log("Node Backend URL:", nodeBackendUrl);
console.log("Python Backend URL:", pythonBackendUrl);
      const response = await axios.post(`${backendUrl}/api/auth/login`, {
        email,
        password
      });

      if (response.status === 200) {
        const data = response.data;
        if (data.success) {
          localStorage.setItem('authToken', data.token);
          setAuthData(data.user);
          toast.success('Login successful!');
          setRedirect(true);
        } else {
          const errorMsg = response.data.msg || 'Login failed';
          toast.error(errorMsg);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.');
    }
  };

  if (redirect) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="login-page">
      <ToastContainer />
      <div className="login-container">
        <div className='login-heading'><h2>Login</h2></div>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email</label>
            <div className="form-input">
              <input
                type="text"
                name="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label>Password</label>
            <div className="form-input">
              <input
                type={passwordVisible ? 'text' : 'password'}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                <Icon icon={passwordVisible ? eye : eyeOff} size={20} />
              </span>
            </div>
          </div>
          <button type="submit" className="login-button">Login</button>
        </form>
        {message && <p className="message">{message}</p>}
        <p className="register-link">
          New here? <a href="/register">Create an account</a>
        </p>
        <p className="forgot-password-link">
          Forgot Password? <a href="/forgot-password">Reset it here</a>
        </p>
      </div>
    </div>
  );
};

export default Login;
