import React, { useState } from 'react';
import axios from 'axios';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Register.css';

const Register = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [loadingSendOtp, setLoadingSendOtp] = useState(false);
  const [loadingVerifyOtp, setLoadingVerifyOtp] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const backendUrl = process.env.REACT_APP_NODE_BACKEND_URL; // Accessing backend URL from .env

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSendOtp = async () => {
    setLoadingSendOtp(true);
    try {
      const response = await axios.post(`${backendUrl}/api/auth/send-otp`, { email });

      if (response.status === 200) {
        const data = response.data;
        if (data.success) {
          setOtpSent(true);
          toast.success('OTP sent to your email!');
        } else {
          toast.error(data.msg || 'Failed to send OTP');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.');
    } finally {
      setLoadingSendOtp(false);
    }
  };

  const handleVerifyOtp = async () => {
    setLoadingVerifyOtp(true);
    try {
      const response = await axios.post(`${backendUrl}/api/auth/verify-otp`, { email, otp });

      if (response.status === 200) {
        const data = response.data;
        if (data.success) {
          setEmailVerified(true);
          toast.success('Email verified successfully!');
        } else {
          toast.error(data.msg || 'Invalid OTP');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.');
    } finally {
      setLoadingVerifyOtp(false);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!emailVerified) {
      toast.error('Please verify your email first.');
      return;
    }
    setLoadingRegister(true);
    try {
      const response = await axios.post(`${backendUrl}/api/auth/register`, {
        email,
        username,
        password,
        phone,
        otp,
      });

      if (response.status === 200) {
        const data = response.data;
        if (data.success) {
          toast.success('Registration successful!', {
            onClose: () => window.location.href = '/login',
          });
        } else {
          toast.error(data.msg || 'Registration failed');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.');
    } finally {
      setLoadingRegister(false);
    }
  };

  return (
    <div className="register-page">
      <div className="register-container">
        <div className='register-heading'><h2>Register</h2></div>
        <form onSubmit={handleRegister}>
          <div className="form-group">
            <label>Email</label>
            <div className='form-input'>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={otpSent}
              />
              <button type="button" onClick={handleSendOtp} disabled={loadingSendOtp || otpSent}>
                {loadingSendOtp ? 'Sending OTP...' : otpSent ? 'OTP Sent' : 'Send OTP'}
              </button>
            </div>
          </div>
          {otpSent && !emailVerified && (
            <div className="form-group">
              <label>OTP</label>
              <div className='form-input'>
                <input
                  type="text"
                  name="otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
                <button type="button" onClick={handleVerifyOtp} disabled={loadingVerifyOtp}>
                  {loadingVerifyOtp ? 'Verifying...' : 'Verify OTP'}
                </button>
              </div>
            </div>
          )}
          <div className="form-group">
            <label>Username</label>
            <div className='form-input'>
              <input
                type="text"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label>Password</label>
            <div className="form-input">
              <input
                type={passwordVisible ? 'text' : 'password'}
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                <Icon icon={passwordVisible ? eye : eyeOff} size={20} />
              </span>
            </div>
          </div>
          <div className="form-group">
            <label>Phone</label>
            <div className='form-input'>
              <input
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>
          </div>
          <button type="submit" className="register-button" disabled={loadingRegister }>
            {loadingRegister ? 'Registering...' : 'Register'}
          </button>
        </form>
        <p className="login-link">
          Already have an account? <a href="/login">Login</a>
        </p>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Register;
